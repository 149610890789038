.onboarding {
  &__skip {
    div {
        z-index:101;
        border: 1px solid #0D25303D;
        border-radius: 0.5em;
        padding: 0.5em;
        padding-left: 1em;
        padding-right: 1em;
        position: absolute;
        color:#000;
        font-weight:bold;
        top: 5em;
        text-decoration:none;
        left: 1em;
        cursor:pointer;

        &:hover {
            opacity:0.8;
        }
    }

}


  .step {
    position: absolute;
    bottom: 4em;
    position: absolute;
    max-width: 900px;
    width: 100%;
    left: 50%;
    transform: translate(-50%, 0%);
  }

  .questions__container {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
  }

  .background__video {
    position: absolute;
    top: 0px;
    display: block;
    height: 100vh;
    width: 100vh;
    left: 50%;
    transform: translate(-50%, 0%);
  }

  .questions__question {
    box-shadow: -4px 4px 0px 0px #00000014;
    background: #ffffff;
    font-size: 24px;
    padding: 0.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-bottom: 1rem;
    border-radius: 1rem;
    display: inline-block;
  }

  .questions__options {
    display: flex;
    width: 100%;
    margin-top: 1rem;
  }

  .questions__option {
    background: #000000;
    padding: 1em;
    display: flex;
    flex-grow: 1;
    margin-right: 1rem;
    color: #fff;
    padding-right: 2rem;
    font-size: 24px;
    border-radius: 1rem;
    cursor: pointer;
    font-weight:bold;
    background-image:url(../../../images/arrow_right_alt.svg);
    background-repeat:no-repeat;
    background-position: 95% 50%;
    width: 33.3%;


    &:hover {
      opacity: 0.8;
    }
  }
}
