.buddy {

  &__container {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    text-align:center;
  }

  &__title {
    font-weight:bold;
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  &__description {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }

  &__options {
    margin-top: 2rem;
    display:flex;
  }

  &__option {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .347);
    width: 33.3%;
    height: 50vh;
    padding: 20px;
    background-size: cover;
    background-position: center;
    border-radius: var(--unit);
    display: flex;
    align-items: flex-end;
    margin-right: 1rem;
    border-radius: 1rem;
    cursor: pointer;
    position: relative;


    &--1 {
        background-image: linear-gradient(0deg, rgb(255, 255, 255) 15%, rgba(255, 255, 255, 0) 60%), url("../../../images/evi.png");
    }

    &--2 {
        background-image: linear-gradient(0deg, rgb(255, 255, 255) 15%, rgba(255, 255, 255, 0) 60%), url("../../../images/child.png");
    }

    &--3 {
        background-image: linear-gradient(0deg, rgb(255, 255, 255) 15%, rgba(255, 255, 255, 0) 60%), url("../../../images/marc.png");
    }

    &__alert {
        position: absolute;
        top: 1rem;
        right: 1rem;
        background-color:#fcd3ce;
        padding: 0.5rem;
        border-radius: 0.2em;
    }

    &__title {
        font-weight: bold;
        font-size: 1.2rem;
    }

    &:hover {
        opacity:0.7;
    }

  }


}