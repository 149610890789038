.home {

      
    &__back {
        a {
            z-index:101;
            border: 1px solid #0D25303D;
            border-radius: 0.5em;
            padding: 0.5em;
            padding-left: 2em;
            padding-right: 1em;
            position: absolute;
            color:#000;
            font-weight:bold;
            top: 5em;
            text-decoration:none;
            left: 1em;
            cursor:pointer;
            background-image:url(../../../images/arrow_forward.svg);
            background-repeat:no-repeat;
            background-position: 10% 50%;
            background-size: 12px 12px;

            &:hover {
                opacity:0.8;
            }
        }

    }
    

    &__container {
        text-align:center; 
        position: fixed;
        bottom: 3rem;
        width: 100%;   
    }

    &__input__container {
        background-color:#FFF;
        border-radius: 3rem;
        border: 1px solid rgba(0, 0, 0, 0.32);
        display:flex;

        max-width: 700px;
        margin-left: auto;
        margin-right: auto;

    }

    &__input {
        display:block;
        border:0;
        flex-grow:1;
        background:none;
        font-size: 1.6rem;
        padding-left: 1rem;
       font-family:inherit;
    }
    &__input__button {
        color:#FFF;
        background: rgba(0, 0, 0, 0.8);
        border-radius: 40px;
        padding: 0.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        margin: 0.5rem;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        font-size: 1.6rem;
        font-weight:bold;
    }

    &__questions {
        display:flex;
        
        margin-top: 2rem;
        padding-left: 1em;
        padding-right: 1em;
        width: calc(100% - 2em);
        max-width: 1100px;
        margin-left: auto;
        margin-right: auto;

    }

    &__question {
        display:flex;
        padding: 1rem;
        padding-right: 3rem;
        width: 33.3%;
        min-height: 7rem;
        margin-right: 1rem;
        align-items: center;
        font-weight:bold;
        font-size: 1.2rem;
        cursor:pointer;
        background-color:#FFEEDE;
        border-radius:0.5rem;
        text-align:left;
        
        background-image:url(../../../images/expand_more.svg);
        background-repeat:no-repeat;
        background-position: 95% 50%;

        &:hover {
            opacity:0.8;
        }
    
    }


  .background__video {
    position: absolute;
    top: 0px;
    display: block;
    height: 100vh;
    width: 100vh;
    left: 50%;
    transform: translate(-50%, 0%);
  }
}