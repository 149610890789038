@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Source+Sans+3:ital,wght@0,200..900;1,200..900&family=Space+Grotesk:wght@300..700&display=swap');

body {
  background: linear-gradient(180deg, rgba(255, 199, 144, 0) 0%, #FFB46C 100%);
  background-size: 100% 100%;
  min-height: 100vh;
  font-family: "Poppins", sans-serif;
}

.header {
  padding: 20px;
  z-index:1001;
  position:relative;
}