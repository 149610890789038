.chat {
    max-width: 1280px;
    margin-right: auto;
    margin-left:auto;
    position: absolute;
    bottom: 0px;
    top: 0px;
    width: 100%;
    left: 50%;
    transform:translate(-50%,0);
    
    &__back {
        a {
            z-index:101;
            border: 1px solid #0D25303D;
            border-radius: 0.5em;
            padding: 0.5em;
            padding-left: 2em;
            padding-right: 1em;
            position: absolute;
            color:#000;
            font-weight:bold;
            top: 5em;
            text-decoration:none;
            left: 1em;
            cursor:pointer;


            background-image:url(../../../images/arrow_forward.svg);
            background-repeat:no-repeat;
            background-position: 10% 50%;
            background-size: 12px 12px;

            &:hover {
                opacity:0.8;
            }
        }

    }
    
    &__video-container {
        position: absolute;
        top: 0px;
        bottom: 0px;
        width: 500px;
        
        video {
            height: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &__overview {
        position: absolute;
        top: 0px;
        right: 3em;
        max-width: 650px;
        width: 100%;
        bottom: 0px;
        overflow-y:scroll;
        padding-top: 3em;
        padding-right: 2em;
    }

    &__message {
        margin-bottom: 1em;
        display:flex;

        &--right {
            justify-content: flex-end;
        }

        &__question {
            padding: 1em;
            color:#FFF;
            background:#000;
            border-radius: 0.5em;
            box-shadow: -4px 4px 0px 0px #00000014;
            max-width: 500px;
            font-size: 1.2em;
        }

        &__answer {
            padding: 1em;
            background:#FFF;
            border-radius: 0.5em;
            box-shadow: -4px 4px 0px 0px #00000014;
            max-width: 500px;

            &__title {
                border-bottom: 1px solid #C254113D;
                padding-bottom: 0.5em;
                margin-bottom: 0.5em;
                font-weight: bold;
                font-size: 1.2em;
            }

            &__copy {
                a {
                    color:#000;
                    text-decoration: underline;
                }
                ol {
                    counter-reset: item;
                    list-style-type: none;
                    padding-left: 2em;
                }
        
                ol > li {
                    position: relative;
                    margin-bottom: 0.5em;
                }
        
                ol > li::before {
                    content: counter(item) " ";
                    counter-increment: item;
                    position: absolute;
                    left: -2em;
                    width: 1.5em;
                    height: 1.5em;
                    line-height: 1.5em;
                    background-color:#FFD6AF;
                    border-radius: 50%;
                    text-align: center;
                    font-weight: bold;
                    color: black;
                }

                ul {
                    list-style-type: disc; /* default style */
                      padding: 10px;
                    margin: 10px;
                }
            }

            &__links {
                padding: 1em;
                border: 1px solid #0000001F;
                font-size: 0.9em;
                &__label {
                   font-weight: bold;
                }

                img {
                    width: 16px;
                    display:inline-block;
                    margin-right: 0.5em;

                }

                a {
                    display:inline;
                    color:#444;
                    text-decoration:none;
                    line-height:1.5;

                    &:hover {
                        text-decoration:underline;
                    }
                }

                &__item {
                    margin-bottom: 0.2em;                    
                }

                &__label {
                    margin-bottom: 0.5em;
                }

            }

            &__image img {
                display:block;
                margin-top: 1em;
                margin-bottom: 1em;
                max-width: 100%;
                border-radius: 0.5em;
                
            }
        }
    }


    &__input__container {
        background-color:#FFF;
        border-radius: 3rem;
        border: 1px solid rgba(0, 0, 0, 0.32);
        display:flex;

        max-width: 700px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 2em;
        margin-bottom: 3em;

    }

    &__input {
        display:block;
        border:0;
        flex-grow:1;
        background:none;
        font-size: 1.2rem;
        padding-left: 1rem;
       font-family:inherit;
    }
    &__input__button {
        color:#FFF;
        background: rgba(0, 0, 0, 0.8);
        border-radius: 40px;
        padding: 0.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        margin: 0.5rem;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        font-size: 1.2rem;
        font-weight:bold;
    }

    &__followup {
        &__button {
            max-width: 400px;
            background:#000000;
            color:#FFF;
            border-radius: 0.5em;
            margin-bottom: 0.5em;
            padding: 0.5em;
            padding-left: 1em;
            padding-right: 3em;
            font-weight:bold;
            font-size: 1.2em;
            cursor:pointer;

            background-image:url(../../../images/arrow_right_alt.svg);
            background-repeat:no-repeat;
            background-position: 95% 50%;

            &:hover {
                opacity:0.8;
            }
            
        }
    }


    &__loader {
        background:#FFF;
        padding: 1em;
        display:inline-block;
        border-radius: 0.5em;
        margin-bottom: 3em;

        /* HTML: <div class="loader"></div> */
        .loader {
            height: 30px;
            aspect-ratio: 6;
            --c: #0000 64%,#000 66% 98%,#0000 101%;
            background:
            radial-gradient(35% 146% at 50% 159%,var(--c)) 0 0,
            radial-gradient(35% 146% at 50% -59%,var(--c)) 25% 100%;
            background-size: calc(100%/3) 50%;
            background-repeat: repeat-x;
            clip-path: inset(0 100% 0 0);
            animation: l2 1s infinite linear;
            margin-left:auto;
            margin-right:auto;
            margin-bottom: 0.5em;
        }
 
    }
}

@keyframes l2{
    90%,to {clip-path: inset(0)}
  }